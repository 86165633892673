import React,{useState} from 'react';
import {Link}  from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {nanoid} from 'nanoid';
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import pic1 from './../../../images/profile/small/pic1.jpg';
import Editable from '../Editable';
import BasicDatatable from '../../components/table/BasicDatatable';


const PageList = () =>{
	
	
	return(
		<>
			<PageTitle activeMenu="page list" motherMenu="dashboard" />
			<div className="row">
				<BasicDatatable></BasicDatatable>
			</div>
			<div className="col-12">
			
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">작업 파일을 여기에 리스트업 해주세요</h4>
					</div>
					<div className="card-body">
						<div className="w-100 table-responsive">
							<div id="example_wrapper" className="dataTables_wrapper">
                            <ul className="star-rating">
                                <li onClick={() => window.location.href = '/shinryu-format'}>
                                    <i className="fa fa-star" /> page format: 기본틀
                                </li>
                            </ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default PageList;