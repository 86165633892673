import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  fileDeleteService,
  formDeleteService,
  formUpdateService,
  getCategoryService,
  getformDetailService,
} from "../../../services/ShinryuService";
import { cateList, formDetail } from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
let _files;
const TemplateDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [id, setId] = useState(searchParams.get("id"));
  const [catlist, setCateList] = useRecoilState(cateList);
  const [content, setContent] = useRecoilState(formDetail);
  const [selectedBigClassName, setSelectedBigClassName] = useState("");
  const [tags, setTags] = useState([[], [], [], [], [], [], [], [], [], []]);
  const [selectedSubType01, setSelectedSubType01] = useState("-");
  const [selectedSubType02, setSelectedSubType02] = useState("-");
  const [title, setTitle] = useState("");
  const [files, setFiles] = useState([]);
  const [originalFiles, setOriginalFiles] = useState([]);
  const [filesUpdated, setFilesUpdated] = useState(false);
  const init = async () => {
    const catList = await getCategoryService();
    const activeCatList = catList.filter((cat) => cat.status !== "INACTIVE");
    setCateList(activeCatList);
    setTags((prevState) =>
      prevState.map((tags, index) => {
        const filteredCats = activeCatList.filter(
          (cat) => cat.categoryBigClassName === String(index)
        );
        return filteredCats.map((cat) => ({
          name: cat.categorySmallClassName,
          id: cat.id,
          status: cat.status,
        }));
      })
    );
    const detail = await getformDetailService(id);

    setContent(detail.form[0]);
    setSelectedSubType01(detail.form[0].category); // Added this line
    setSelectedSubType02(detail.form[0].type); // Added this line
    setTitle(detail.form[0].title); // Added this line
    const existingFiles = detail.file.map((file) => ({
      ...file,
      uploaded: true,
    }));
    // setFiles(existingFiles);
    setOriginalFiles(existingFiles); // Store the initial files in originalFiles
  };
  useEffect(() => {
    if (searchParams.get("id")) {
      init();
    }
  }, [searchParams.get("id")]);
  const handleEditorChange = (content, editor) => {
    setContent(content);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const _files = e.target.files;
    const fileList = Array.from(_files);
    fileList.forEach((file) => (file.uploaded = false));

    // Filter out the original files and add the new ones
    const updatedFiles = files.concat(
      fileList.filter((file) => !files.some((f) => f.name === file.name))
    );

    setFiles(updatedFiles);
    setFilesUpdated(true); // 파일이 추가되었음을 표시
  };
  const handleSubmitUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (filesUpdated) {
      // 새 파일만 formData에 추가합니다.
      files.forEach((file) => {
        if (!file.uploaded) {
          formData.append("files", file, encodeURIComponent(file.name));
        }
      });
    } else {
      // 파일이 변경되지 않았을 경우, 파일 이름을 formData에 추가합니다.
      const existingFileIds = originalFiles.map((file) => file.fileId);
      formData.append("existingFileIds", JSON.stringify(existingFileIds));
    }

    await formUpdateService(
      id,
      formData,
      selectedSubType01,
      selectedSubType02,
      title,
      content
    );
  };

  const handleDeleteSubmit = async () => {
    const result = window.confirm("本当に削除してもよろしいですか？");
    if (result) {
      await formDeleteService(id);
      await history.goBack();
    }
  };
  return (
    <>
      <PageTitle activeMenu="様式登録" motherMenu="dashboard" />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="pt-4 px-3">
              <div className="settings-form">
                <div className="form-group mb-3">
                  <div className="basic-form">
                    <form onSubmit={handleSubmitUpload}>
                      <div style={{ display: "block", width: "100%" }}>
                        <div className="form-head d-flex mb-3 align-items-center ">
                          <select
                            value={selectedSubType01}
                            onChange={(e) =>
                              setSelectedSubType01(e.target.value)
                            }
                            className="form-control col-02 mr-3">
                            <option value="-">カテゴリー１</option>
                            {catlist
                              .filter((cat) => cat.categoryBigClassName === "2") // filter by categoryBigClassName
                              .map((cat) => (
                                <option
                                  key={cat.id}
                                  value={cat.categorySmallClassName}>
                                  {cat.categorySmallClassName}
                                </option>
                              ))}
                          </select>
                          <select
                            value={selectedSubType02}
                            onChange={(e) =>
                              setSelectedSubType02(e.target.value)
                            }
                            className="form-control col-02 mr-3">
                            <option value="-">カテゴリー２</option>
                            {catlist
                              .filter((cat) => cat.categoryBigClassName === "3") // filter by categoryBigClassName
                              .map((cat) => (
                                <option
                                  key={cat.id}
                                  value={cat.categorySmallClassName}>
                                  {cat.categorySmallClassName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <input
                          type="text"
                          placeholder="件名"
                          className="form-control"
                          value={content.title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <div className="basic-form">
                          <div className="form-group">
                            <Editor
                              apiKey="o1dtwll4elgd2oiuxnvmxcawshwg7h40mlme0dprvzz387hp"
                              initialValue={content.content}
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image code charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
                                  "bullist numlist outdent indent | removeformat | help ",
                                content_style: "body { color: #7e7e7e }",
                              }}
                              onEditorChange={handleEditorChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <div className="basic-form custom_file_input">
                          <div className="input-group mb-3">
                            <div className="custom-file">
                              <input
                                type="file"
                                id="file"
                                name="file"
                                multiple={true}
                                className="custom-file-input"
                                onChange={handleUpload}
                              />
                              <label className="custom-file-label">
                                file選択
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-5 mx-auto">
                        {files.map((d, i) => {
                          return (
                            <Button
                              variant="dark light btn-sm"
                              className={i > 0 ? `ml-3` : ``}>
                              <i
                                className="fa fa-solid fa-close font-18 mr-3 "
                                onClick={() => {
                                  setFiles(
                                    files.filter((file, index) => index !== i)
                                  );
                                }}></i>
                              {d.name}
                            </Button>
                          );
                        })}
                        {originalFiles.map((d, i) => {
                          const url = `https://docs.google.com/viewer?url=${d.fileUrl}&embedded=true`;

                          // 파일 확장자 추출
                          let extension = "";
                          if (d.fileUrl) {
                            const url = `https://docs.google.com/viewer?url=${d.fileUrl}&embedded=true`;
                            extension = d.fileUrl
                              .split(".")
                              .pop()
                              .toLowerCase();
                          }

                          // 이미지 확장자 배열
                          const imageExtensions = ["jpg", "jpeg", "png", "gif"];

                          return (
                            <div key={i} className="mb-3">
                              {/* 이미지 파일일 경우 */}

                              {imageExtensions.includes(extension) ? (
                                <img
                                  src={d.fileUrl}
                                  width="300"
                                  style={{ margin: "2px" }}
                                  alt=""
                                />
                              ) : (
                                /* 이미지 파일 이외의 파일일 경우 */
                                <iframe
                                  src={url}
                                  title={`viewer${i}`}
                                  width={600}
                                  height={600}></iframe>
                              )}
                              <br></br>
                              <a
                                // href={d.fileUrl}
                                target="_blank"
                                rel="noreferrer">
                                <Button variant="dark light btn-sm">
                                  <i
                                    className="fa fa-solid fa-close font-18 mr-3 "
                                    onClick={() => {
                                      setOriginalFiles(
                                        originalFiles.filter(
                                          (file, index) => index !== i
                                        )
                                      );
                                      fileDeleteService(d.id);
                                    }}></i>
                                  {d.id ? d.fileName : d.name}
                                </Button>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                      <Button
                        className="btn btn-primary mr-3 rounded btn-sm"
                        type="submit">
                        登録
                      </Button>
                      <Button
                        className="btn mr-3 rounded btn-sm"
                        variant="danger"
                        onClick={handleDeleteSubmit}>
                        削除
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TemplateDetail;
