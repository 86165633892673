import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  getQueListService,
  getUserListService,
} from "../../../services/ShinryuService";
import { getLocalStorage } from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
const QueList = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [queList, setQueList] = useState([]);
  const [admin, setAdmin] = useState("");
  const [userList, setUserList] = useState([]);
  const itemsPerPage = 30;
  // 유저 데이터를 가져오는 함수
  const init = async () => {
    const ques = await getQueListService(); // query가 필요 없으면 제거
    // companyId가 "XXYYCSMJZR"인 유저들만 필터링
    const filteredQues = ques.filter((que) => que.companyId === "XXYYCSMJZR");
    const users = await getUserListService(); // query가 필요 없으면 제거
    // companyId가 "XXYYCSMJZR"인 유저들만 필터링
    const filteredUsers = users.filter(
      (user) => user.companyId === "XXYYCSMJZR"
    );

    setUserList(filteredUsers);

    setQueList(filteredQues); // 필터링된 유저 리스트를 상태로 저장
    return filteredQues;
  };

  // 초기화 함수
  useEffect(() => {
    const initialize = async () => {
      await init();
      // console.log("유저", userList);
      const adminInfo = await getLocalStorage("admininfo");
      setAdmin(adminInfo);
    };

    initialize();
  }, []);
  // 페이지네이션에 필요한 데이터 계산
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = queList.slice(startIndex, endIndex);
  const totalPages = Math.ceil(queList.length / itemsPerPage);

  // 페이지 변경 핸들러
  const handlePagination = (newPage) => {
    if (newPage < 0 || newPage >= totalPages) return;
    setCurrentPage(newPage);
  };

  const truncateText = (text, length) => {
    return text.length > length ? `${text.substring(0, length)}...` : text;
  };
  // Helper function to determine if a string is a URL
  const isURL = (str) => /^https?:\/\//.test(str);

  // Helper function to check if a string is numeric
  const isNumeric = (str) => /^\d+$/.test(str);
  const getNickName = (userId) => {
    const user = userList.find((user) => user.idx === userId);
    return user ? user.nickName : "Unknown"; // user가 없을 경우 "Unknown" 표시
  };
  const getColumnBody = (rowData) => {
    if (rowData.outputData.startsWith("https://")) {
      // URL로 시작하는 경우
      return (
        <a
          href={rowData.outputData}
          download="desired-filename.ext" // 원하는 파일명으로 설정
          target="_blank"
          rel="noopener noreferrer">
          ダウンロード
        </a>
      );
    } else if (/^\d+$/.test(rowData.outputData)) {
      // 숫자만으로 이루어진 경우
      const postId = rowData.outputData;
      const editUrl = `https://hyper-engawa.com/wp-admin/post.php?post=${postId}&action=edit`;
      return (
        <a href={editUrl} target="_blank" rel="noopener noreferrer">
          確認
        </a>
      );
    } else {
      // 다른 경우
      return rowData.outputData.length > 30
        ? rowData.outputData.slice(0, 30) + "..."
        : rowData.outputData;
    }
  };
  return (
    <>
      <div className="custom-row">
        <PageTitle activeMenu="キュー" motherMenu="shinryu" />
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="tableFixHead2">
                <DataTable
                  value={currentItems}
                  paginator
                  rows={30}
                  selectionMode="single"
                  dataKey="idx"
                  sortField="createdAt" // Set default sort field
                  sortOrder={-1} // 1 for ascending, -1 for descending
                  removableSort>
                  <Column field="idx" header="id" sortable />
                  <Column
                    field="jobId"
                    header="種類"
                    sortable
                    body={(rowData) =>
                      rowData.jobId === 2
                        ? "youtube"
                        : rowData.jobId === 3
                        ? "ppt"
                        : "Unknown"
                    }
                  />
                  <Column
                    field="inputData"
                    header="変換元"
                    sortable
                    body={(rowData) => {
                      const inputData = JSON.parse(rowData.inputData || "{}");

                      // originalName이 존재할 경우 그 값을 표시
                      if (inputData.originalName) {
                        return inputData.originalName;
                      }

                      // youtubeUrl이 있을 경우 링크로 표시
                      const url = inputData.youtubeUrl || rowData.inputData;
                      if (url.startsWith("https://www.youtube.com")) {
                        return (
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer">
                            {url}
                          </a>
                        );
                      }

                      // 기본적으로 긴 텍스트는 잘라서 표시
                      return truncateText(rowData.inputData, 30);
                    }}
                  />
                  <Column
                    field="status"
                    header="ステータス"
                    sortable
                    body={(rowData) => {
                      switch (rowData.status) {
                        case "COMPLETE":
                          return "完了";
                        case "PENDING":
                          return "待機";
                        case "FAIL":
                          return "失敗";
                        default:
                          return rowData.status; // 기본값으로 원래 상태 값을 반환
                      }
                    }}
                  />
                  <Column
                    field="outputData"
                    header="結果"
                    sortable
                    body={(rowData) => {
                      const outputData = rowData.outputData;
                      if (isURL(outputData)) {
                        return (
                          <a
                            href={outputData}
                            target="_blank"
                            rel="noopener noreferrer">
                            ダウンロード
                          </a>
                        );
                      } else if (isNumeric(outputData)) {
                        const editUrl = `https://hyper-engawa.com/wp-admin/post.php?post=${outputData}&action=edit`;
                        return (
                          <a
                            href={editUrl}
                            target="_blank"
                            rel="noopener noreferrer">
                            確認
                          </a>
                        );
                      }
                      return outputData; // For any other case
                    }}
                  />
                  <Column
                    field="userId"
                    header="作成者"
                    sortable
                    body={(rowData) => getNickName(rowData.userId)}
                  />
                  <Column
                    field="createdAt"
                    header="日付"
                    sortable
                    body={(rowData) =>
                      format(
                        new Date(rowData.createdAt),
                        "yyyy-MM-dd(eee) HH:mm",
                        { locale: ja }
                      )
                    }
                  />
                </DataTable>
              </div>
              <div className="dataTables_wrapper">
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    {startIndex + 1} ～ {Math.min(endIndex, queList.length)}{" "}
                    件表示 （総 {queList.length} 件）
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          {/* <!-- /# card --> */}
        </Col>
      </Row>
    </>
  );
};
export default QueList;
