import { Editor } from "@tinymce/tinymce-react";
import React from "react";

class App extends React.Component {
  handleEditorChange = (content, editor) => {};

  render() {
    return (
      <Editor
        apiKey="o1dtwll4elgd2oiuxnvmxcawshwg7h40mlme0dprvzz387hp"
        initialValue="<p>This is the initial content of the editor</p>"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image code charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
            "bullist numlist outdent indent | removeformat | help ",
          content_style: "body { color: #7e7e7e }",
        }}
        onEditorChange={this.handleEditorChange}
      />
    );
  }
}

export default App;
