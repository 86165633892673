import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { getCategoryService, sendform } from "../../../services/ShinryuService";
import { cateList } from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
const WriteTemplate = () => {
  const [catlist, setCateList] = useRecoilState(cateList);
  const [selectedBigClassName, setSelectedBigClassName] = useState("");
  const [tags, setTags] = useState([[], [], [], [], [], [], [], [], [], []]);
  const [selectedSubType01, setSelectedSubType01] = useState("-");
  const [selectedSubType02, setSelectedSubType02] = useState("-");
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const catList = await getCategoryService();
    const activeCatList = catList.filter((cat) => cat.status !== "INACTIVE");
    setCateList(activeCatList);
    setTags((prevState) =>
      prevState.map((tags, index) => {
        const filteredCats = activeCatList.filter(
          (cat) => cat.categoryBigClassName === String(index)
        );
        return filteredCats.map((cat) => ({
          name: cat.categorySmallClassName,
          id: cat.id,
          status: cat.status,
        }));
      })
    );
  };
  const handleEditorChange = (content, editor) => {
    setContent(content);
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    const _files = e.target.files;
    const fileList = Array.from(_files);

    setFiles([...files, ...fileList]);
    // await sendNotificationInform(fileList)
  };

  const handleSubmitUpload = async (e) => {
    e.preventDefault();

    // return false;
    // if (files.length == 0) {
    //   alert("Choose file");
    //   return false;
    // }

    await sendform(files, selectedSubType01, selectedSubType02, title, content);
  };
  return (
    <>
      <PageTitle activeMenu="様式登録" motherMenu="dashboard" />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="pt-4 px-3">
              <div className="settings-form">
                <div className="form-group mb-3">
                  <div className="basic-form">
                    <form onSubmit={handleSubmitUpload}>
                      <div style={{ display: "block", width: "100%" }}>
                        <div className="form-head d-flex mb-3 align-items-center ">
                          <select
                            value={selectedSubType01}
                            onChange={(e) =>
                              setSelectedSubType01(e.target.value)
                            }
                            className="form-control col-02 mr-3">
                            <option value="-">カテゴリー１</option>
                            {catlist
                              .filter((cat) => cat.categoryBigClassName === "2") // filter by categoryBigClassName
                              .map((cat) => (
                                <option
                                  key={cat.id}
                                  value={cat.categorySmallClassName}>
                                  {cat.categorySmallClassName}
                                </option>
                              ))}
                          </select>
                          <select
                            value={selectedSubType02}
                            onChange={(e) =>
                              setSelectedSubType02(e.target.value)
                            }
                            className="form-control col-02 mr-3">
                            <option value="-">カテゴリー２</option>
                            {catlist
                              .filter((cat) => cat.categoryBigClassName === "3") // filter by categoryBigClassName
                              .map((cat) => (
                                <option
                                  key={cat.id}
                                  value={cat.categorySmallClassName}>
                                  {cat.categorySmallClassName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3">
                        <input
                          type="text"
                          placeholder="件名"
                          className="form-control"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <div className="basic-form">
                          {/* <form onSubmit={(e) => e.preventDefault()}> */}
                          <div className="form-group">
                            {/* <textarea
                              className="form-control"
                              rows="7"
                              placeholder="メッセージ本文"
                              value={content}
                              onChange={(e) => setContent(e.target.value)}
                              id="comment"></textarea> */}
                            <Editor
                              apiKey="o1dtwll4elgd2oiuxnvmxcawshwg7h40mlme0dprvzz387hp"
                              initialValue="<p>本文入力</p>"
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image code charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
                                  "bullist numlist outdent indent | removeformat | help ",
                                content_style: "body { color: #7e7e7e }",
                              }}
                              onEditorChange={handleEditorChange}
                            />
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <div className="basic-form custom_file_input">
                          {/* <form action="#"> */}
                          <div className="input-group mb-3">
                            <div className="custom-file">
                              <input
                                type="file"
                                id="file"
                                name="file"
                                multiple={true}
                                className="custom-file-input"
                                onChange={handleUpload}
                              />
                              <label className="custom-file-label">
                                file選択
                              </label>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                      <div className="form-group mb-5 mx-auto">
                        {/* <Button variant="dark light btn-sm ">
                      <i className="fa fa-solid fa-plus font-18 mr-3 "></i>
                      添付ファイル追加
                    </Button> */}
                        {files.map((d, i) => {
                          return (
                            <Button
                              variant="dark light btn-sm"
                              className={i > 0 ? `ml-3` : ``}>
                              <i
                                className="fa fa-solid fa-close font-18 mr-3 "
                                onClick={() => {
                                  setFiles(
                                    files.filter((file, index) => index !== i)
                                  );
                                }}></i>
                              {d.name}
                            </Button>
                          );
                        })}
                      </div>
                      <Button
                        className="btn btn-primary mr-3 rounded btn-sm"
                        type="submit">
                        登録
                      </Button>
                      {/* <Button className="btn mr-3 rounded btn-sm" variant="danger">
                    削除
                  </Button> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WriteTemplate;
