import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  createCategoryService,
  deleteCategoryService,
  getCategoryService,
  updateCategoryService,
} from "../../../services/ShinryuService";
import { cateList } from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";

const Setting = () => {
  const [tagsAddedCount, setTagsAddedCount] = useState(0);
  const [tags1, setTags1] = useState([]);
  const [tags2, setTags2] = useState([]);
  const [tags3, setTags3] = useState([]);
  const [tags4, setTags4] = useState([]);
  const [tags5, setTags5] = useState([]);
  const [tags6, setTags6] = useState([]);
  const [tags7, setTags7] = useState([]);
  const [tags8, setTags8] = useState([]);
  const [tags9, setTags9] = useState([]);
  const [tags10, setTags10] = useState([]);
  const [tagIds, setTagIds] = useState([]);
  const [catlist, setCateList] = useRecoilState(cateList);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [inputs, setInputs] = useState(Array.from({ length: 10 }, () => ""));
  const [tags, setTags] = useState([[], [], [], [], [], [], [], [], [], []]);
  const tagGenerators = tags.length;
  const [isKeyReleased, setIsKeyReleased] = useState(
    Array.from({ length: tagGenerators }, () => false)
  );
  const [selectedTagIndices, setSelectedTagIndices] = useState(
    Array.from({ length: tagGenerators }, () => -1)
  );

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const catList = await getCategoryService();
        const activeCatList = catList.filter(
          (cat) => cat.status !== "INACTIVE"
        );
        setCateList(catList);
        setTags((prevState) =>
          prevState.map((tags, index) => {
            const filteredCats = activeCatList.filter(
              (cat) => cat.categoryBigClassName === String(index)
            );
            return filteredCats.map((cat) => ({
              name: cat.categorySmallClassName,
              id: cat.id,
              status: cat.status,
            }));
          })
        );
        setIsLoading(false);
        setHasError(false);
      } catch (error) {
        setIsLoading(false);
        setHasError(true);
      }
    };

    loadCategories();
  }, [setCateList, tagsAddedCount]);
  //   const init = async () => {
  //     const catList = await getCategoryService();
  //     setCateList(catList);
  //     catList.forEach((cat, index) => {
  //       const tagIndex = index % tagGenerators;
  //       const currentTags = tags[tagIndex];
  //       currentTags.push(cat.categorySmallClassName);
  //       setTags((prevTags) => {
  //         const updatedTags = [...prevTags];
  //         updatedTags[tagIndex] = currentTags;
  //         return updatedTags;
  //       });
  //     });
  //   };
  const createTag = async (tag, tagGenerator) => {
    await createCategoryService(tag, tagGenerator);
    setTagsAddedCount((count) => count + 1);
  };
  const deleteTag = async (index, tagGenerator, id) => {
    setTags((prevState) =>
      prevState.map((tags, i) => {
        if (i === tagGenerator) {
          const updatedTags = [...tags];
          updatedTags.splice(index, 1);
          return updatedTags;
        } else {
          return tags;
        }
      })
    );
    await deleteCategoryService(id, tagGenerator);
    setTagsAddedCount((count) => count + 1);
  };
  const updateTag = async (index, value, tagGenerator, id) => {
    setTags((prevState) =>
      prevState.map((tags, i) => {
        if (i === tagGenerator) {
          const updatedTags = [...tags];
          updatedTags[index] = { name: value, id: id, status: "ACTIVE" };
          return updatedTags;
        } else {
          return tags;
        }
      })
    );
    await updateCategoryService(id, value, tagGenerator);
    setSelectedTagIndices((prevState) =>
      prevState.map((value, i) => (i === tagGenerator ? -1 : value))
    );
  };

  const onChange = (e, tagGenerator) => {
    const { value } = e.target;
    setInputs((prevState) =>
      prevState.map((input, i) => (i === tagGenerator ? value : input))
    );
  };

  const onKeyDown = async (e, tagGenerator) => {
    const { key } = e;
    const trimmedInput = inputs[tagGenerator].trim();
    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags[tagGenerator].includes(trimmedInput)
    ) {
      e.preventDefault();

      if (selectedTagIndices[tagGenerator] === -1) {
        setTags((prevState) =>
          prevState.map((tags, i) =>
            i === tagGenerator ? [...tags, trimmedInput] : tags
          )
        );
        await createTag(trimmedInput, tagGenerator);
      } else if (selectedTagIndices[tagGenerator] !== -1) {
        const selectedTag =
          tags[tagGenerator][selectedTagIndices[tagGenerator]];
        updateTag(
          selectedTagIndices[tagGenerator],
          trimmedInput,
          tagGenerator,
          selectedTag.id
        );
      }
      setInputs((prevState) =>
        prevState.map((input, i) => (i === tagGenerator ? "" : input))
      );
    }
    if (
      key === "Backspace" &&
      !inputs[tagGenerator].length &&
      tags[tagGenerator].length &&
      isKeyReleased[tagGenerator] &&
      selectedTagIndices[tagGenerator] === -1
    ) {
      const tagsCopy = [...tags[tagGenerator]];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags((prevState) =>
        prevState.map((tags, i) => (i === tagGenerator ? tagsCopy : tags))
      );
      setInputs((prevState) =>
        prevState.map((input, i) => (i === tagGenerator ? poppedTag : input))
      );
    }
    setIsKeyReleased((prevState) =>
      prevState.map((value, i) => (i === tagGenerator ? false : value))
    );
  };

  const onKeyUp = (tagGenerator) => {
    setIsKeyReleased((prevState) =>
      prevState.map((value, i) => (i === tagGenerator ? true : value))
    );
  };

  const selectTag = (index, tagGenerator, name, id) => {
    setSelectedTagIndices((prevState) =>
      prevState.map((value, i) => (i === tagGenerator ? index : value))
    );
    setInputs((prevState) =>
      prevState.map((input, i) => (i === tagGenerator ? name : input))
    );
    setTagIds((prevState) =>
      prevState.map((tagId, i) => (i === tagGenerator ? id : tagId))
    );
  };
  const tagGeneratorTexts = [
    "お知らせ　カテゴリー１",
    "お知らせ　カテゴリー２",
    "様式　カテゴリー1",
    "様式　カテゴリー2",
    "所属",
    "グループ",
    "保険区分",
    "社員区分",
    "業務区分",
    "在籍区分",
  ];
  return (
    <>
      <PageTitle activeMenu="設定" motherMenu="dashboard" />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="form-row">
              {[...Array(tagGenerators)].map((_, tagGenerator) => (
                <div className="form-group col-md-6">
                  <div className="setting-tag" key={tagGenerator}>
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "20px",
                      }}>
                      {tagGeneratorTexts[tagGenerator]}
                    </div>
                    {tags[tagGenerator].map((tag, index) => (
                      <div
                        className={`tag ${
                          index === selectedTagIndices[tagGenerator]
                            ? "selected"
                            : ""
                        }`}
                        key={index}>
                        <div
                          onClick={() =>
                            selectTag(index, tagGenerator, tag.name, tag.id)
                          }>
                          {tag.name}
                        </div>
                        <button
                          onClick={() =>
                            deleteTag(index, tagGenerator, tag.id)
                          }>
                          削除
                        </button>
                      </div>
                    ))}
                    <input
                      value={inputs[tagGenerator]}
                      placeholder="Enter a tag"
                      onKeyDown={(e) => onKeyDown(e, tagGenerator)}
                      onKeyUp={() => onKeyUp(tagGenerator)}
                      onChange={(e) => onChange(e, tagGenerator)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
