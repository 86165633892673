import React, { useEffect, useState } from "react";
/// React router dom
import { Route, Switch } from "react-router-dom";
/// Css
import "./chart.css";
import "./index.css";
import "./step.css";

/// Layout
import Footer from "./layouts/Footer";
import Nav from "./layouts/nav";

/// Dashboard
//import Home from './components/Dashboard/Home'
//import MyWallet from './components/Dashboard/MyWallet'
//import Invoices from './components/Dashboard/Invoices'
//import CardsCenter from './components/Dashboard/CardsCenter'
//import Transactions from './components/Dashboard/Transactions'
//import TransactionsDetails from './components/Dashboard/TransactionsDetails'

/// Dashboard
import CoinDetails from "./components/Dashboard/CoinDetails";
import DashboardDark from "./components/Dashboard/DashboardDark";
import MarketCapital from "./components/Dashboard/MarketCapital";
import Portofolio from "./components/Dashboard/Portofolio";
import Transactions from "./components/Dashboard/Transactions";
import Wallet from "./components/Dashboard/Wallet";
import Home from "./pages/Shinryu/UserList";

import Task from "./components/Dashboard/Task";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";

/// Product List
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import EcomCustomers from "./components/AppsMenu/Shop/Customers/Customers";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";

/// Charts
import ChartJs from "./components/charts/Chartjs";
import SparklineChart from "./components/charts/Sparkline";
import ApexChart from "./components/charts/apexcharts";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";

/// Bootstrap
import UiAccordion from "./components/bootstrap/Accordion";
import UiAlert from "./components/bootstrap/Alert";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiGrid from "./components/bootstrap/Grid";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiModal from "./components/bootstrap/Modal";
import UiPagination from "./components/bootstrap/Pagination";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";

/// Widget
import Widget from "./pages/Widget";

/// Table
import BootstrapTable from "./components/table/BootstrapTable";
import DataTable from "./components/table/DataTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import SortingTable from "./components/table/SortingTable/SortingTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Pickers from "./components/Forms/Pickers/Pickers";
import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
import WizardForm from "./components/Forms/ReduxWizard/Index";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Wizard from "./components/Forms/Wizard/Wizard";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pages
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import LockScreen from "./pages/LockScreen";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Todo from "./pages/Todo";

//Scroll To Top
import { setLocalStorage } from "../store/recoil";
import ScrollToTop from "./layouts/ScrollToTop";
import AddUser from "./pages/Shinryu/AddUser";
import Contact from "./pages/Shinryu/Contact";
import Gensen from "./pages/Shinryu/Gensen";
import Kinmumeisai from "./pages/Shinryu/Kinmumeisai";
import Kyuyomeisai from "./pages/Shinryu/Kyuyomeisai";
import Notice from "./pages/Shinryu/Notice";
import PageFormat from "./pages/Shinryu/PageFormat";
import PageList from "./pages/Shinryu/PageList";
import SendGensen from "./pages/Shinryu/SendGensen";
import ArchiveGen from "./pages/Shinryu/ArchiveGen";
import DocumentGen from "./pages/Shinryu/DocumentGen";
import SentNoti from "./pages/Shinryu/SentNoti";
import Setting from "./pages/Shinryu/Setting";
import TemplateDetail from "./pages/Shinryu/TemplateDetail";
import TemplateList from "./pages/Shinryu/TemplateList";
import UserDetail from "./pages/Shinryu/UserDetail";
import UserList from "./pages/Shinryu/UserList";
import QueList from "./pages/Shinryu/QueList";
import WorkManagement from "./pages/Shinryu/WorkManagement";
import WriteTemplate from "./pages/Shinryu/WriteTemplate";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);
  useEffect(() => {
    checkPath();
  }, [path]);

  const checkPath = () => {
    setLocalStorage("path", path);
  };

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "dashboard-dark", component: DashboardDark },
    { url: "my-wallet", component: Wallet },
    { url: "coin-details", component: CoinDetails },
    { url: "portfolio", component: Portofolio },
    { url: "transactions", component: Transactions },
    { url: "market-capital", component: MarketCapital },
    { url: "task", component: Task },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "post-details", component: PostDetails },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-media-object", component: UiMediaObject },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },
    { url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },

    /// Widget
    { url: "widget-basic", component: Widget },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: EcomCustomers },

    /// Form

    { url: "form-redux", component: ReduxForm },
    { url: "form-redux-wizard", component: WizardForm },
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },

    /// table
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },
    { url: "table-filtering", component: FilteringTable },
    { url: "table-sorting", component: SortingTable },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    { url: "todo", component: Todo },

    //shinru page in here !!
    { url: "shinryu-format", component: PageFormat },
    { url: "shinryu-pagelist", component: PageList },
    { url: "lomi-userlist", component: UserList },
    { url: "lomi-que", component: QueList },
    { url: "lomi-userdetail", component: UserDetail },
    { url: "shinryu-notice", component: Notice },
    { url: "shinryu-wms", component: WorkManagement },
    { url: "archive-gen", component: ArchiveGen },
    { url: "document-gen", component: DocumentGen },
    { url: "shinryu-gensen", component: SendGensen },
    { url: "shinryu-template", component: TemplateList },
    { url: "shinryu-writetemplate", component: WriteTemplate },
    { url: "shinryu-contact", component: Contact },
    { url: "shinryu-kyuyomeisai", component: Kyuyomeisai },
    { url: "shinryu-gensentb", component: Gensen },
    { url: "shinryu-setting", component: Setting },
    { url: "shinryu-adduser", component: AddUser },
    { url: "shinryu-templatedetail", component: TemplateDetail },
    { url: "shinryu-kinmumeisai", component: Kinmumeisai },
    { url: "shinryu-sent", component: SentNoti },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}>
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}>
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
