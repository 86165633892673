import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import * as XLSX from "xlsx";
import {
  getCategoryService,
  getNoticeUserList,
  getUserListFilterListService,
  sendNotificationInform,
} from "../../../services/ShinryuService";
import { cateList, getLocalStorage } from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
const Notice = () => {
  const [filterLists, setFilterLists] = useState({
    belongTo: [],
    employmentType: [],
    enrollmentType: [],
    insuranceType: [],
    workScope: [],
    workerType: [],
    userGroup: [],
  });
  const history = useHistory();
  const [catlist, setCateList] = useRecoilState(cateList);
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedBigClassName, setSelectedBigClassName] = useState("");
  const [tags, setTags] = useState([[], [], [], [], [], [], [], [], [], []]);
  const [selectedBelongTo, setSelectedBelongTo] = useState("-");
  const [selectedSubType01, setSelectedSubType01] = useState("-");
  const [selectedSubType02, setSelectedSubType02] = useState("-");
  const [selectedInsuranceType, setSelectedInsuranceType] = useState("-");
  const [selectedemploymentType, setSelectedemploymentType] = useState("-");
  const [selectedenrollmentType, setSelectedenrollmentType] = useState("-");
  const [selectedworkScope, setSelectedworkScope] = useState("-");
  const [selectedworkerType, setSelectedworkerType] = useState("-");
  const [selectedUserGroup, setSelectedUserGroup] = useState("-");
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const [csv, setCsvList] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [admin, setAdmin] = useState("");
  const sortedWorkers = useMemo(() => {
    let sorted = [...filteredWorkers];

    if (sortColumn) {
      sorted = sorted.sort((a, b) => {
        // 비어있는 문자열 혹은 null 처리
        if (!a[sortColumn] || !b[sortColumn]) {
          return !a[sortColumn] ? -1 : 1;
        }

        // 문자열이나 숫자에 따라 비교 방법을 선택합니다.
        if (isNaN(a[sortColumn]) || isNaN(b[sortColumn])) {
          // 문자열 비교
          return a[sortColumn].localeCompare(b[sortColumn]);
        } else {
          // 숫자 비교
          const valueA = parseInt(a[sortColumn]);
          const valueB = parseInt(b[sortColumn]);

          if (valueA < valueB) return -1;
          if (valueA > valueB) return 1;
          return 0;
        }
      });

      if (sortDirection === "desc") {
        sorted = sorted.reverse();
      }
    }

    return sorted;
  }, [filteredWorkers, sortColumn, sortDirection]);
  const handleButtonClick = (id) => {
    history.push(`/lomi-userdetail?id=${id}`);
  };
  const handleButtonClick2 = (id) => {
    history.push(`/lomi-userdetail?id=${id}&key=message`);
  };
  const itemsPerPage = 60000;
  const totalPages = Math.ceil(sortedWorkers.length / itemsPerPage);
  const pageCount = Math.ceil(sortedWorkers.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedWorkers.slice(startIndex, endIndex);
  const handleSort = (column) => {
    // Only proceed if the clicked column is different from the current sort column
    if (sortColumn !== column) {
      setSortColumn(column);
      setSortDirection("asc");
    } else {
      // If the same column is clicked again, reverse the sorting direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    }
    setCurrentPage(0);
  };
  useEffect(() => {
    init();
    getLocalStorage("admininfo")
      .then((admin) => {
        setAdmin(admin);
      })
      .catch((error) => {
        console.error("에러 발생:", error);
      });
  }, []);

  const init = async () => {
    const filterList = await getUserListFilterListService();
    setFilterLists(filterList);

    const catList = await getCategoryService();
    const activeCatList = catList.filter((cat) => cat.status !== "INACTIVE");
    setCateList(activeCatList);

    setTags((prevState) =>
      prevState.map((tags, index) => {
        const filteredCats = activeCatList.filter(
          (cat) => cat.categoryBigClassName === String(index)
        );
        return filteredCats.map((cat) => ({
          name: cat.categorySmallClassName,
          id: cat.id,
          status: cat.status,
        }));
      })
    );
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    const _files = e.target.files;
    const fileList = Array.from(_files);

    setFiles([...files, ...fileList]);
    // await sendNotificationInform(fileList)
  };
  const handleEditorChange = (content, editor) => {
    setContent(content);
  };
  const handleListUpdate = async () => {
    const userListResponse = await getNoticeUserList(
      selectedBelongTo,
      selectedInsuranceType,
      selectedemploymentType,
      selectedenrollmentType,
      selectedworkScope,
      selectedworkerType,
      selectedUserGroup
    );

    const userIds = userListResponse.userList.map((user) => user.id);

    setFilteredWorkers(userListResponse.userList);
  };

  // activeUsers 상태가 변경될 때마다 이 useEffect가 호출됩니다.
  useEffect(() => {}, [activeUsers]);

  const handleSubmitUpload = async (e) => {
    e.preventDefault();

    // return false;
    // if (files.length == 0) {
    //   alert("Choose file");
    //   return false;
    // }

    //전체 발송인 경우에서만 한번 더 물어보기
    if (
      selectedBelongTo === "-" &&
      selectedInsuranceType === "-" &&
      selectedenrollmentType === "-" &&
      selectedworkScope === "-" &&
      selectedworkerType === "-" &&
      selectedUserGroup === "-"
    ) {
      // alert("Choose filter")
      const result = window.confirm("通知を送ってもよろしいですか？");

      if (result) {
        await sendNotificationInform(
          files,
          selectedBelongTo,
          selectedSubType01,
          selectedSubType02,
          selectedInsuranceType,
          selectedemploymentType,
          selectedenrollmentType,
          selectedworkScope,
          selectedworkerType,
          selectedUserGroup,
          title,
          content
        );
      }
      return false;
    }

    await sendNotificationInform(
      files,
      selectedBelongTo,
      selectedSubType01,
      selectedSubType02,
      selectedInsuranceType,
      selectedemploymentType,
      selectedenrollmentType,
      selectedworkScope,
      selectedworkerType,
      selectedUserGroup,
      title,
      content
    );
  };
  const handleCsv = async () => {
    const CsvList = await getNoticeUserList(
      selectedBelongTo,
      selectedInsuranceType,
      selectedemploymentType,
      selectedenrollmentType,
      selectedworkScope,
      selectedworkerType,
      selectedUserGroup
    );
    setCsvList(CsvList);

    let newList = [];
    for (let i = 0; i < CsvList.userList.length; i++) {
      newList.push({
        id: CsvList.userList[i].id,
        メールアドレス: CsvList.userList[i].userEmail,
        // ログインID: CsvList.userList[i].userUniqueId,
        // パスワード: CsvList.userList[i].userPassword,
        ログインID: "***",
        パスワード: "***",
        隊員番号: CsvList.userList[i].userNumber,
        裏番号: CsvList.userList[i].userLastNumber,
        名前: CsvList.userList[i].userName,
        フリガナ: CsvList.userList[i].userNameHurigana,
        性別: CsvList.userList[i].sex,
        // 生年月日: CsvList.userList[i].userBirth,
        // 住所01: CsvList.userList[i].address01,
        // 住所02: CsvList.userList[i].address02,
        // 住所03: CsvList.userList[i].address03,
        // 電話番号: CsvList.userList[i].userHousePhone,
        // 携帯電話: CsvList.userList[i].userPhone,
        // FAX番号: CsvList.userList[i].userFaxNumber,
        生年月日: "***",
        住所01: "***",
        住所02: "***",
        住所03: "***",
        電話番号: "***",
        携帯電話: "***",
        FAX番号: "***",
        所属: CsvList.userList[i].belongTo,
        保険区分: CsvList.userList[i].insuranceType,
        社員区分: CsvList.userList[i].workerType,
        業務区分: CsvList.userList[i].workScope,
        在籍区分: CsvList.userList[i].enrollmentType,
        グループ: CsvList.userList[i].userGroup,
        雇用期間_始: CsvList.userList[i].employmentStartAt,
        雇用期間_終: CsvList.userList[i].employmentEndAt,
        // 緊急連絡先関係: CsvList.userList[i].emergencyContactRelation,
        // 緊急連絡先名前: CsvList.userList[i].emergencyContactName,
        // 緊急連絡先住所: CsvList.userList[i].emergencyContactAddress,
        // 緊急連絡先電話番号: CsvList.userList[i].emergencyContactPhone,
        緊急連絡先関係: "***",
        緊急連絡先名前: "***",
        緊急連絡先住所: "***",
        緊急連絡先電話番号: "***",
        権限: CsvList.userList[i].authority,
        メモ: CsvList.userList[i].userMemo,
      });
    }
    downloadExcel(newList);
  };
  const downloadExcel = (csv) => {
    const worksheet = XLSX.utils.json_to_sheet(csv);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };
  return (
    <>
      <PageTitle activeMenu="通知" motherMenu="dashboard" />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div>
              <div className="settings-form">
                <form onSubmit={handleSubmitUpload}>
                  <div style={{ display: "block", width: "100%" }}>
                    <div className="form-head d-flex mb-3 align-items-center ">
                      <select
                        value={selectedSubType01}
                        onChange={(e) => setSelectedSubType01(e.target.value)}
                        className={`form-control col-02 mr-3 ${
                          selectedSubType01 === "-" ? "default-option" : ""
                        }`}>
                        <option value="-">カテゴリー１</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "0") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>

                      <select
                        value={selectedSubType02}
                        onChange={(e) => setSelectedSubType02(e.target.value)}
                        className={`form-control col-02 mr-3 ${
                          selectedSubType02 === "-" ? "default-option" : ""
                        }`}>
                        <option value="-">カテゴリー２</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "1") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-head d-flex mb-3 align-items-center ">
                      <select
                        value={selectedUserGroup}
                        onChange={(e) => setSelectedUserGroup(e.target.value)}
                        className={`form-control col-02 mr-3 ${
                          selectedUserGroup === "-" ? "default-option" : ""
                        }`}>
                        <option value="-">グループ</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "5") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>
                      {/* <select
                        value={selectedemploymentType}
                        onChange={(e) => setSelectedemploymentType(e.target.value)}
                        className="form-control col-02 mr-3">
                        <option value="">グループ</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "5") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select> */}
                      <select
                        value={selectedBelongTo}
                        onChange={(e) => setSelectedBelongTo(e.target.value)}
                        className={`form-control col-02 mr-3 ${
                          selectedBelongTo === "-" ? "default-option" : ""
                        }`}>
                        <option value="-">所属</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "4")
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>

                      <select
                        value={selectedInsuranceType}
                        onChange={(e) =>
                          setSelectedInsuranceType(e.target.value)
                        }
                        className={`form-control col-02 mr-3 ${
                          selectedInsuranceType === "-" ? "default-option" : ""
                        }`}>
                        <option value="-">保険区分</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "6") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>

                      <select
                        value={selectedworkerType}
                        onChange={(e) => setSelectedworkerType(e.target.value)}
                        className={`form-control col-02 mr-3 ${
                          selectedworkerType === "-" ? "default-option" : ""
                        }`}>
                        <option value="-">社員区分</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "7") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>
                      <select
                        value={selectedworkScope}
                        onChange={(e) => setSelectedworkScope(e.target.value)}
                        className={`form-control col-02 mr-3 ${
                          selectedworkScope === "-" ? "default-option" : ""
                        }`}>
                        <option value="-">業務区分</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "8") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>
                      <select
                        value={selectedenrollmentType}
                        onChange={(e) =>
                          setSelectedenrollmentType(e.target.value)
                        }
                        className={`form-control col-02 mr-3 ${
                          selectedenrollmentType === "-" ? "default-option" : ""
                        }`}>
                        <option value="-">在籍区分</option>
                        {catlist
                          .filter((cat) => cat.categoryBigClassName === "9") // filter by categoryBigClassName
                          .map((cat) => (
                            <option
                              key={cat.id}
                              value={cat.categorySmallClassName}>
                              {cat.categorySmallClassName}
                            </option>
                          ))}
                      </select>
                      <Button
                        className="btn btn-primary mr-3 rounded btn-sm col-2"
                        onClick={handleListUpdate}>
                        ユーザーリスト表示
                      </Button>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <input
                      type="text"
                      placeholder="件名"
                      className="form-control"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <div className="basic-form">
                      {/* <form onSubmit={(e) => e.preventDefault()}> */}
                      <div className="form-group">
                        <Editor
                          apiKey="o1dtwll4elgd2oiuxnvmxcawshwg7h40mlme0dprvzz387hp"
                          initialValue="<p>本文入力</p>"
                          init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image code charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
                              "bullist numlist outdent indent | removeformat | help ",
                            content_style: "body { color: #7e7e7e }",
                          }}
                          onEditorChange={handleEditorChange}
                        />
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <div className="basic-form custom_file_input">
                      {/* <form action="#"> */}
                      <div className="input-group mb-3">
                        <div className="custom-file">
                          <input
                            type="file"
                            id="file"
                            name="file"
                            multiple={true}
                            className="custom-file-input"
                            onChange={handleUpload}
                          />
                          <label className="custom-file-label">file選択</label>
                        </div>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                  <div className="form-group mb-5 mx-auto">
                    {/* <Button variant="dark light btn-sm ">
                      <i className="fa fa-solid fa-plus font-18 mr-3 "></i>
                      添付ファイル追加
                    </Button> */}
                    {files.map((d, i) => {
                      return (
                        <Button
                          variant="dark light btn-sm"
                          className={i > 0 ? `ml-3` : ``}>
                          <i
                            className="fa fa-solid fa-close font-18 mr-3 "
                            onClick={() => {
                              setFiles(
                                files.filter((file, index) => index !== i)
                              );
                            }}></i>
                          {d.name}
                        </Button>
                      );
                    })}
                  </div>

                  <Button
                    className="btn btn-primary mr-3 rounded btn-sm"
                    type="submit">
                    一括送信
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="tableFixHead">
              <Table responsive hover>
                <thead className="thead-info">
                  <tr>
                    <th onClick={() => handleSort("userNumber")}>隊員番号</th>
                    <th onClick={() => handleSort("userLastNumber")}>BNo.</th>
                    <th onClick={() => handleSort("userName")}>名前</th>
                    <th onClick={() => handleSort("sex")}>性別</th>
                    <th onClick={() => handleSort("belongTo")}>所属</th>
                    <th onClick={() => handleSort("group")}>グループ</th>
                    <th onClick={() => handleSort("insuranceType")}>
                      保険区分
                    </th>
                    <th onClick={() => handleSort("workerType")}>社員区分</th>
                    <th onClick={() => handleSort("workScope")}>業務区分</th>
                    <th onClick={() => handleSort("employmentEndAt")}>
                      雇用期間
                    </th>
                    <th onClick={() => handleSort("enrollmentType")}>
                      在籍区分
                    </th>
                    <th>メッセージ</th>
                  </tr>
                </thead>
                <tbody className="tbody-info">
                  {currentItems.map((d, i) => {
                    return (
                      <tr
                        key={d.id}
                        onClick={() => handleButtonClick(d.userNumber)}>
                        <td>{d.userNumber}</td>
                        <td>{d.userLastNumber}</td>
                        <td>{d.userName}</td>
                        <td>{d.sex}</td>
                        <td>{d.belongTo}</td>
                        <td>
                          <span className="ellipsis">
                            {d.userGroup
                              ? d.userGroup
                                  .split(",")
                                  .filter((value) => value.length > 1)
                                  .join(" / ")
                              : ""}
                          </span>
                        </td>
                        <td>{d.insuranceType}</td>
                        <td>{d.workerType}</td>
                        <td>{d.workScope}</td>
                        <td>~{d.employmentEndAt}</td>
                        <td>{d.enrollmentType}</td>
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                            handleButtonClick2(d.userNumber);
                          }}>
                          <i className="fa fa-regular fa-envelope font-18 align-middle mx-auto"></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="dataTables_wrapper">
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  {startIndex + 1} ～ {Math.min(endIndex, sortedWorkers.length)}{" "}
                  件表示 （総
                  {sortedWorkers.length} 件）
                </div>
                <div className="dataTables_paginate paging_simple_numbers">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate">
                    <Link
                      className={`paginate_button previous ${
                        currentPage === 0 ? "disabled" : ""
                      }`}
                      onClick={() => {
                        if (currentPage === 0) {
                          return;
                        }
                        setCurrentPage(currentPage - 1);
                      }}>
                      戻る
                    </Link>

                    {/* {Array.from({ length: totalPages }).map((_, i) => (
                        <Link
                          key={i}
                          className={`paginate_button ${
                            currentPage === i ? "current" : ""
                          }`}
                          onClick={() => setCurrentPage(i)}>
                          {i + 1}
                        </Link>
                      ))} */}

                    <Link
                      className={`paginate_button next ${
                        currentPage === totalPages - 1 ? "disabled" : ""
                      }`}
                      onClick={() => {
                        if (currentPage === totalPages - 1) {
                          return;
                        }
                        setCurrentPage(currentPage + 1);
                      }}>
                      次へ
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
              {/* <div
                style={
                  admin === "管理者C" || admin === "管理者B"
                    ? { display: "none" }
                    : { display: "block" }
                }> */}
              <div>
                <Button
                  className="btn btn-primary rounded btn-sm"
                  onClick={() => handleCsv(csv)}>
                  Excelダウンロード
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Notice;
