import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import * as XLSX from "xlsx";
import {
  getCategoryService,
  getUserCsvService,
  getUserListFilterListService,
  getUserListService,
} from "../../../services/ShinryuService";
import { cateList, getLocalStorage, userList } from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
const UserList = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [userList, setUserList] = useState([]);
  const [admin, setAdmin] = useState("");
  const itemsPerPage = 30;
  // 유저 데이터를 가져오는 함수
  const init = async () => {
    const users = await getUserListService(); // query가 필요 없으면 제거
    // companyId가 "XXYYCSMJZR"인 유저들만 필터링
    const filteredUsers = users.filter(
      (user) => user.companyId === "XXYYCSMJZR"
    );

    setUserList(filteredUsers); // 필터링된 유저 리스트를 상태로 저장
    console.log("users", filteredUsers);
    return filteredUsers;
  };

  // 초기화 함수
  useEffect(() => {
    const initialize = async () => {
      await init();

      const adminInfo = await getLocalStorage("admininfo");
      setAdmin(adminInfo);
    };

    initialize();
  }, []);
  // 페이지네이션에 필요한 데이터 계산
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = userList.slice(startIndex, endIndex);
  const totalPages = Math.ceil(userList.length / itemsPerPage);

  // 페이지 변경 핸들러
  const handlePagination = (newPage) => {
    if (newPage < 0 || newPage >= totalPages) return;
    setCurrentPage(newPage);
  };

  // 유저 상세 페이지로 이동
  const handleButtonClick = (id) => {
    history.push(`/lomi-userdetail?id=${id}`);
  };
  return (
    <>
      <div className="custom-row">
        <PageTitle activeMenu="ユーザー管理" motherMenu="shinryu" />
        <div className="custom-header">
          <div
            style={
              admin == "管理者C" ? { display: "none" } : { display: "block" }
            }>
            <Link to="/shinryu-adduser">
              <Button className="btn btn-primary rounded btn-sm" type="submit">
                新規登録
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="tableFixHead2">
                <DataTable
                  value={currentItems}
                  paginator
                  rows={30}
                  selectionMode="single"
                  dataKey="id"
                  removableSort
                  onRowClick={(e) => handleButtonClick(e.data.idx)}>
                  <Column field="idx" header="No." sortable />
                  <Column field="nickName" header="名前" sortable />
                  <Column field="email" header="メールアドレス" sortable />
                </DataTable>
              </div>
              <div className="dataTables_wrapper">
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    {startIndex + 1} ～ {Math.min(endIndex, userList.length)}{" "}
                    件表示 （総 {userList.length} 件）
                  </div>
                </div>
              </div>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div
                  style={
                    admin == "管理者C"
                      ? { display: "none" }
                      : { display: "block" }
                  }>
                  <Link to="/shinryu-adduser">
                    <Button
                      className="btn btn-primary rounded btn-sm"
                      type="submit">
                      新規登録
                    </Button>
                  </Link>
                </div>
              </div>
            </Card.Body>
          </Card>
          {/* <!-- /# card --> */}
        </Col>
      </Row>
    </>
  );
};
export default UserList;
