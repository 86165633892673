import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import pic1 from "./../../../images/profile/small/pic1.jpg";
import Editable from "../Editable";

const WorkManagement = () => {
  return (
    <>
      <PageTitle activeMenu="業務管理" motherMenu="dashboard" />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="pt-4 px-3">
              <div className="settings-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group mb-3">
                    <div className="basic-form">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group">
                          <select
                            defaultValue={"option"}
                            className="form-control">
                            <option>隊員登録</option>
                            <option>現任日程</option>
                            <option>雇用保険加入通知書</option>
                            <option>雇用保険対象者通知</option>
                            <option>入社時書類</option>
                            <option>社保加入書類</option>
                            <option>障がい者</option>
                            <option>健診</option>
                            <option>ストレスチェック</option>
                            <option>年末調整</option>
                            <option>源泉徴収票</option>
                            <option>社保料変更通知</option>
                            <option>住民税特別徴収額通知</option>
                            <option>その他</option>
                          </select>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <div className="basic-form custom_file_input">
                      <form action="#">
                        <div className="input-group mb-3">
                          <div className="custom-file">
                            <input type="file" className="custom-file-input" />
                            <label className="custom-file-label">
                              file選択
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      placeholder="件名"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group mb-3">
                    <div className="basic-form">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            rows="7"
                            placeholder="メッセージ本文"
                            id="comment"></textarea>
                        </div>
                      </form>
                    </div>
                  </div>

                  <Button
                    className="btn btn-primary mr-3 rounded btn-sm"
                    type="submit">
                    一括送信
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WorkManagement;
