import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

import logo from "../../../images/lomi2.png";
import logo2 from "../../../images/logo_b.png";
import logoText from "../../../images/logo-text.png";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        <img
          className={toggle ? "logo-abbr2" : "logo-abbr"}
          src={toggle ? logo2 : logo}
          alt=""
        />
      </Link>

      <div className="nav-control" onClick={() => setToggle(!toggle)}>
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
