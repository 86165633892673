import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import jaLocale from "date-fns/locale/ja";
import format from "date-fns/format";

function BasicDatePicker(props) {
  const [selectedDate, handleDateChange] = useState(new Date());
  class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
      return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date) {
      return format(date, "MMMd日", { locale: this.locale });
    }
  }
  return (
    <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
      <DatePicker
        autoOk
        label=""
        clearable
        format="yyyy/MM/dd"
        disableFuture
        value={selectedDate}
        onChange={handleDateChange}
        okLabel="決定"
        cancelLabel="キャンセル"
      />
    </MuiPickersUtilsProvider>
  );
}

export default BasicDatePicker;
