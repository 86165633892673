import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import pic1 from "./../../../images/profile/small/pic1.jpg";
import Editable from "../Editable";
import { format } from "date-fns";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
} from "react-bootstrap";
import {
  getformService,
  getCategoryService,
} from "../../../services/ShinryuService";
import {
  userList,
  userBelongToFilterListState,
  cateList,
  formList,
} from "../../../store/recoil";
import { useRecoilState } from "recoil";
const TemplateList = () => {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [formlist, setformList] = useRecoilState(formList);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterLists, setFilterLists] = useState({
    cat1: [],
    cat2: [],
  });
  const [catlist, setCateList] = useRecoilState(cateList);
  const [selectedBigClassName, setSelectedBigClassName] = useState("");
  const [tags, setTags] = useState([[], [], [], [], [], [], [], [], [], []]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedcat1, setSelectedCat1] = useState("");
  const [selectedcat2, setSelectedCat2] = useState("");
  const [filteredWorkers, setFilteredWorkers] = useState([]);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const bigClassNames = catlist.reduce((acc, curr) => {
    const { categoryBigClassName, categorySmallClassName } = curr;
    if (acc[categoryBigClassName]) {
      acc[categoryBigClassName].push(curr);
    } else {
      acc[categoryBigClassName] = [curr];
    }
    return acc;
  }, {});
  const handleFilterAndSearch = () => {
    const filteredWorkers = formlist.filter((d) => {
      // Check if the worker matches the selected belongTo option
      if (selectedcat1 && d.category !== selectedcat1) {
        return false;
      }

      // Check if the worker matches the selected insuranceType option
      // if (selectedGroup && d.insuranceType !== selectedGroup) {
      //   return false;
      // }

      // Check if the worker matches the selected insuranceType option
      if (selectedcat2 && d.type !== selectedcat2) {
        return false;
      }

      const rowString = Object.values(d).join("").toLowerCase();
      if (query && !rowString.includes(query.toLowerCase())) {
        return false;
      }

      // If all filters match, include the worker in the filtered array
      return true;
    });
    setFilteredWorkers(filteredWorkers);
    setCurrentPage(0);
  };

  const handleSort = (column) => {
    // If the same column is clicked again, reverse the sorting direction
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      setCurrentPage(0);
    } else {
      setSortColumn(column);
      setSortDirection("asc");
      setCurrentPage(0);
    }
  };
  const sortedWorkers = useMemo(() => {
    let sorted = [...filteredWorkers];

    if (sortColumn) {
      sorted = sorted.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (sortColumn === "createdAt") {
          // Sort by createdAt in descending order
          return new Date(valueB) - new Date(valueA);
        }

        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      });

      if (sortDirection === "asc") {
        sorted = sorted.reverse();
      }
    }

    return sorted;
  }, [filteredWorkers, sortColumn, sortDirection]);
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const catList = await getCategoryService();
    const activeCatList = catList.filter((cat) => cat.status !== "INACTIVE");
    setCateList(activeCatList);
    setTags((prevState) =>
      prevState.map((tags, index) => {
        const filteredCats = activeCatList.filter(
          (cat) => cat.categoryBigClassName === String(index)
        );
        return filteredCats.map((cat) => ({
          name: cat.categorySmallClassName,
          id: cat.id,
          status: cat.status,
        }));
      })
    );

    const forms = await getformService();

    setformList(forms.formList);
    setFilteredWorkers(forms.formList);
  };

  const handleButtonClick = (formId) => {
    history.push(`/shinryu-templatedetail?id=${formId}`);
  };
  const itemsPerPage = 10;
  const totalPages = Math.ceil(sortedWorkers.length / itemsPerPage);
  const pageCount = Math.ceil(sortedWorkers.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedWorkers.slice(startIndex, endIndex);
  const handlePagination = (newPage) => {
    if (newPage < 0) {
      return;
    }
    setCurrentPage(newPage);
  };
  const isPageNegative = startIndex < 0 || endIndex > sortedWorkers.length;
  return (
    <>
      <PageTitle activeMenu="様式" motherMenu="shinryu" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <div style={{ display: "block", width: "100%" }}>
                <div className="form-head d-flex mb-3 align-items-center ">
                  <select
                    value={selectedcat1}
                    onChange={(e) => setSelectedCat1(e.target.value)}
                    className="form-control col-02 mr-3">
                    <option value="">カテゴリー1</option>
                    {catlist
                      .filter((cat) => cat.categoryBigClassName === "2") // filter by categoryBigClassName
                      .map((cat) => (
                        <option key={cat.id} value={cat.categorySmallClassName}>
                          {cat.categorySmallClassName}
                        </option>
                      ))}
                  </select>

                  <select
                    value={selectedcat2}
                    onChange={(e) => setSelectedCat2(e.target.value)}
                    className="form-control col-02 mr-3">
                    <option value="">カテゴリー2</option>
                    {catlist
                      .filter((cat) => cat.categoryBigClassName === "3") // filter by categoryBigClassName
                      .map((cat) => (
                        <option key={cat.id} value={cat.categorySmallClassName}>
                          {cat.categorySmallClassName}
                        </option>
                      ))}
                  </select>

                  <div className="input-group search-area rounded d-inline-flex ml-auto ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="検索"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleFilterAndSearch();
                        }
                      }}
                    />
                    <div
                      className="input-group-append"
                      onClick={handleFilterAndSearch}>
                      <span className="input-group-text">
                        <i className="flaticon-381-search-2" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Table responsive hover>
                <thead className="thead-info">
                  <tr>
                    <th>#</th>
                    <th
                      className="text-left width200"
                      onClick={() => handleSort("category")}>
                      カテゴリー1
                    </th>
                    <th
                      className="text-left width200"
                      onClick={() => handleSort("type")}>
                      カテゴリー2
                    </th>
                    <th
                      className="text-left"
                      onClick={() => handleSort("title")}>
                      様式名
                    </th>
                    <th onClick={() => handleSort("createdAt")}>登録日</th>
                  </tr>
                </thead>
                <tbody className="tbody-info">
                  {currentItems.map((d, i) => {
                    return (
                      <tr
                        key={d.id}
                        onClick={() => handleButtonClick(d.formId)}>
                        <td className="width100">{i + 1}</td>
                        <td className="text-left width200">{d.category}</td>
                        <td className="text-left width200">{d.type}</td>
                        <td className="text-left text-primary">{d.title}</td>
                        <td className="width200">
                          {format(new Date(d.createdAt), "yyyy/MM/dd")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="dataTables_wrapper">
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    {startIndex + 1} ～{" "}
                    {Math.min(endIndex, sortedWorkers.length)} 件表示{" "}
                    （総{sortedWorkers.length} 件）
                  </div>
                  <div className="dataTables_paginate paging_simple_numbers">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate">
                      <Link
                        className={`paginate_button previous ${
                          currentPage === 0 ? "disabled" : ""
                        }`}
                        onClick={() => {
                          if (currentPage === 0) {
                            return;
                          }
                          setCurrentPage(currentPage - 1);
                        }}>
                        戻る
                      </Link>

                      {/* {Array.from({ length: totalPages }).map((_, i) => (
                        <Link
                          key={i}
                          className={`paginate_button ${
                            currentPage === i ? "current" : ""
                          }`}
                          onClick={() => setCurrentPage(i)}>
                          {i + 1}
                        </Link>
                      ))} */}

                      <Link
                        className={`paginate_button next ${
                          currentPage === totalPages - 1 ? "disabled" : ""
                        }`}
                        onClick={() => {
                          if (currentPage === totalPages - 1) {
                            return;
                          }
                          setCurrentPage(currentPage + 1);
                        }}>
                        次へ
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div></div>
                <div>
                  <Link to="/shinryu-writetemplate">
                    <Button
                      className="btn btn-primary rounded btn-sm"
                      type="submit">
                      新規登録
                    </Button>
                  </Link>
                </div>
              </div>
            </Card.Body>
          </Card>
          {/* <!-- /# card --> */}
        </Col>
      </Row>
    </>
  );
};
export default TemplateList;
