import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  sendNotificationScheduler,
  sendNotificationStub,
  sendPpt,
  getJobsService,
} from "../../../services/ShinryuService";
import PageTitle from "../../layouts/PageTitle";

const DocumentGen = () => {
  const [title, setTitle] = useState("");
  const [subType01, setSubtype01] = useState("給与明細");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(0); // タイマーの状態
  const [jobs, setJobs] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState("file選択");
  const handleUpload = async (e) => {
    e.preventDefault();
    const _files = e.target.files;
    const fileList = Array.from(_files);

    // 파일 배열을 상태에 추가
    setFiles((prevFiles) => [...prevFiles, ...fileList]);

    // 파일 이름을 상태에 업데이트
    if (fileList.length > 0) {
      setFileNames(fileList.map((file) => file.name).join(", "));
    } else {
      setFileNames("file選択"); // 파일이 선택되지 않았을 경우 기본 텍스트
    }
  };
  const handleSubmitUpload = async (e) => {
    e.preventDefault();

    // Check if files are selected
    if (files.length === 0) {
      alert("ファイルを選択してください");
      return;
    }

    // Define PowerPoint file types
    const allowedExtensions = [".ppt", ".pptx"];

    // Check if any of the selected files are PowerPoint files
    const isPowerPointFile = files.some(
      (file) =>
        allowedExtensions.includes(file.name.slice(-4).toLowerCase()) ||
        allowedExtensions.includes(file.name.slice(-5).toLowerCase())
    );

    if (!isPowerPointFile) {
      alert("PowerPointのファイルを選択してください");
      return;
    }

    // Start countdown of 5 minutes (300 seconds)
    setTimer(300);
    setIsButtonDisabled(true);

    await sendPpt(jobs[1], files);
  };

  const init = async () => {
    const job = await getJobsService();
    // console.log("좝", job);
    setJobs(job);
    return job; // 반환이 필요한 경우
  };
  useEffect(() => {
    const initialize = async () => {
      await init(); // 반환값이 필요 없으면 굳이 할당하지 않아도 됨
    };

    initialize(); // async 함수 실행
  }, []);
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);

      // カウントダウンが0になったらクリア
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setIsButtonDisabled(false); // タイマーが終わったらボタンを有効化
    }
  }, [timer]);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  return (
    <>
      <PageTitle activeMenu="報告書変換" motherMenu="dashboard" />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div>
              <div className="settings-form">
                <form onSubmit={handleSubmitUpload}>
                  <div className="form-group mb-3">
                    <div className="basic-form custom_file_input">
                      <form action="#">
                        <div className="input-group mb-3">
                          <div className="custom-file">
                            <input
                              type="file"
                              id="file"
                              name="file"
                              multiple={false}
                              className="custom-file-input"
                              onChange={handleUpload}
                            />
                            <label className="custom-file-label">
                              {fileNames}
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <Button
                    className="btn btn-primary mr-3 rounded btn-sm"
                    disabled={isButtonDisabled}
                    type="submit">
                    {isButtonDisabled ? "変換中..." : "変換開始"}
                  </Button>
                  {/* タイマーの表示 */}
                  {timer > 0 && (
                    <div className="mt-2">
                      <p>残り時間: {formatTime(timer)}</p>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DocumentGen;
