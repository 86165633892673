import React,{useState} from 'react';
import {Link}  from "react-router-dom";
import {Modal} from 'react-bootstrap';
import {nanoid} from 'nanoid';
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import pic1 from './../../../images/profile/small/pic1.jpg';
import Editable from '../Editable';


const PageFormat = () =>{
	
	
	return(
		<>
			<PageTitle activeMenu="page format" motherMenu="dashboard" />
			<div className="col-12">
			
				<div className="card">
					<div className="card-header">
						<h4 className="card-title">페이지 틀</h4>
					</div>
					<div className="card-body">
						<div className="w-100 table-responsive">
							<div id="example_wrapper" className="dataTables_wrapper">
                           
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PageFormat;