import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import format from "date-fns/format";
import parse from "date-fns/parse";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { createUserService } from "../../../services/ShinryuService";
import { cateList, getLocalStorage } from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
const AddUser = () => {
  class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
      return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date) {
      return format(date, "MMMd日", { locale: this.locale });
    }
  }
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  // const id = searchParams.get("id");
  const [id, setId] = useState(searchParams.get("id"));
  const [activeToggle, setActiveToggle] = useState("setting");
  const [userGroupList, setUserGroupList] = useState([
    "交通(工事)",
    "交通(施設)",
    "施設",
  ]);
  const [catlist, setCateList] = useRecoilState(cateList);
  const [selectedBigClassName, setSelectedBigClassName] = useState("");
  const [indefiniteEnd, setIndefiniteEnd] = useState(false);
  const [tags, setTags] = useState([[], [], [], [], [], [], [], [], [], []]);
  const [japaneseEra, setJapaneseEra] = useState("");
  const [admin, setAdmin] = useState("");
  const [formValues, setFormValues] = useState({
    email: "",
    nickName: "",
    password: "",
    companyName: "TOHO-LEO",
    companyId: "XXYYCSMJZR",
    authority: "従業員",
    // add more inputs and groups as needed
  });
  useEffect(() => {
    getLocalStorage("admininfo")
      .then((admin) => {
        setAdmin(admin);
      })
      .catch((error) => {
        console.error("에러 발생:", error);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? value : value;
    setFormValues((prevValues) => {
      if (type === "checkbox") {
        if (checked) {
          return {
            ...prevValues,
            userGroup: [...prevValues.userGroup, newValue],
          };
        } else {
          return {
            ...prevValues,
            userGroup: prevValues.userGroup.filter((v) => v !== newValue),
          };
        }
      } else {
        return {
          ...prevValues,
          [name]: newValue,
        };
      }
    });
  };
  const handleDateChange1 = (date) => {
    if (isValid(date)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        userBirth: date,
      }));
    }
    const formattedDate2 = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
      era: "short",
      year: "numeric",
    }).format(date);
    setJapaneseEra(formattedDate2);
  };

  const handleDateChange2 = (date) => {
    if (isValid(date)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        employmentStartAt: date,
      }));
    }
  };

  const handleDateChange3 = (date) => {
    if (!indefiniteEnd) {
      // Add this condition
      setFormValues((prevValues) => ({
        ...prevValues,
        employmentEndAt: date,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedValues = { ...formValues };

    // Check if userName, userUniqueId, userPassword are empty
    if (
      formValues.nickName === "" ||
      formValues.email === "" ||
      formValues.password === ""
    ) {
      alert("お名前とメールアドレス,パスワードは必須項目です。");
      return; // Stop further execution
    }

    await createUserService(formattedValues);
  };

  // Prevent form submission on Enter key press
  document.addEventListener("keydown", function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  });

  const parsedDate = isValid(new Date(formValues.employmentEndAt))
    ? new Date(formValues.employmentEndAt)
    : parse(formValues.employmentEndAt, "yyyyMMdd", new Date());
  return (
    <>
      <PageTitle activeMenu="ユーザー登録" motherMenu="社員管理" />
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body px-3">
            <div className="default-tab">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <Link
                    to="#profile-settings"
                    data-toggle="tab"
                    onClick={() => setActiveToggle("setting")}
                    className={`nav-link ${
                      activeToggle === "setting" ? "active show" : ""
                    }`}>
                    ユーザー情報
                  </Link>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  id="profile-settings"
                  className={`tab-pane fade ${
                    activeToggle === "setting" ? "active show" : ""
                  }`}>
                  <div className="pt-4 px-3">
                    <div className="settings-form">
                      <form onSubmit={handleSubmit}>
                        <div className="form-row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="font-weight-bold">Email</label>
                            <input
                              name="email"
                              value={formValues.email}
                              onChange={handleInputChange}
                              type="email"
                              placeholder="Email"
                              className="form-control"
                              readOnly={admin !== "SUDO"}
                              style={
                                admin !== "SUDO"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="font-weight-bold">
                              パスワード
                            </label>
                            <input
                              name="password"
                              value={formValues.password}
                              onChange={handleInputChange}
                              type="password"
                              placeholder="パスワード"
                              className="form-control"
                              readOnly={admin !== "SUDO"}
                              style={
                                admin !== "SUDO"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="font-weight-bold">お名前</label>
                            <input
                              name="nickName"
                              value={formValues.nickName}
                              onChange={handleInputChange}
                              type="nickName"
                              placeholder="お名前"
                              className="form-control"
                              readOnly={admin !== "SUDO"}
                              style={
                                admin !== "SUDO"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group mb-2 col-md-4">
                            <label className="font-weight-bold">権限</label>
                            <div className="form-group">
                              <select
                                name="authority"
                                value={formValues.authority}
                                onChange={handleInputChange}
                                className="form-control"
                                disabled={admin !== "SUDO"}
                                style={
                                  admin !== "SUDO"
                                    ? { backgroundColor: "#ddd" }
                                    : {}
                                }>
                                <option value="">権限選択</option>
                                <option value="SUDO">SUDO</option>
                                <option value="DRAFT">DRAFT</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <Button
                          className="btn btn-primary mr-3 rounded btn-sm"
                          type="submit"
                          style={
                            admin == "DRAFT"
                              ? { display: "none" }
                              : { display: "block" }
                          }>
                          登録
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddUser;
