import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Alert } from "react-bootstrap";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import pic1 from "./../../../images/profile/small/pic1.jpg";
import Editable from "../Editable";
import axios from 'axios';

const Contact = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("message", message);

    try {
      const axiosInstance = axios.create({
        withCredentials: true,
      });
      alert("メッセージが送信されました。");
      await axiosInstance.post("https://script.google.com/a/macros/workingbee.jp/s/AKfycbwETfGi_b4MYlp32i0xDdEt24Q62EcWaAiz_l7IWJKMtCJ2jVp_ZPJdI8oZjtgcY0clYQ/exec", formData);

    } catch (error) {
      console.error('An error occurred while sending the message:', error);
    }
  };

  return (
    <>
      <PageTitle activeMenu="開発者へのお問合せ" motherMenu="dashboard" />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="pt-4 px-3">
              <div className="settings-form">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      name="subject"
                      placeholder="件名"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <div className="basic-form">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="7"
                          placeholder="メッセージ本文"
                          id="comment"
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <Button
                    className="btn btn-primary rounded btn-sm"
                    type="submit">
                    送信
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;