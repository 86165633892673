import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Editor } from "@tinymce/tinymce-react";
import format from "date-fns/format";
import jaLocale from "date-fns/locale/ja";
import parse from "date-fns/parse";
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, Card, Table } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  DeleteUserService,
  getCategoryService,
  getNotification,
  getUserDetailServiceNumber,
  getUserUpdateService,
  sendNotificationPerson,
  getUserListService,
} from "../../../services/ShinryuService";
import {
  cateList,
  getLocalStorage,
  notiList,
  userDetail,
} from "../../../store/recoil";
import PageTitle from "../../layouts/PageTitle";
import profile from "./../../../images/profile/profile.png";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const UserDetail = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();

  const [formValues, setFormValues] = useState({
    userEmail: "",
    userName: "",
    userNumber: "",
    userPassword: "",
    userGrant: "",
  });
  const [admin, setAdmin] = useState("");

  useEffect(() => {
    // URL에서 id 가져오기
    const idFromURL = searchParams.get("id");
    if (idFromURL) {
      fetchUserData(idFromURL);
      getLocalStorage("admininfo").then(setAdmin).catch(console.error);
    }
  }, [location.search]);

  const fetchUserData = async (idFromURL) => {
    // 유저 리스트 가져오기
    const users = await getUserListService();

    // URL에서 가져온 id를 숫자로 변환 (필요시)
    const parsedId = Number(idFromURL);

    // companyId가 "XXYYCSMJZR"인 유저들 중 id가 일치하는 유저 찾기
    const user = users.find(
      (user) => user.companyId === "XXYYCSMJZR" && user.idx === parsedId
    );
    console.log("user", user); // 찾은 user 값 확인
    if (user) {
      setFormValues({
        userEmail: user.email,
        userName: user.nickName,
        userNumber: user.idx,
        userPassword: user.password,
        userGrant: user.grant,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formValues.userName || !formValues.userNumber) {
      alert("隊員番号または姓名が空白状態です。");
      return;
    }
    // 업데이트 서비스 호출 후 페이지 초기화
    await getUserUpdateService(formValues, formValues.userNumber);
    fetchUserData(formValues.userNumber);
  };

  // const handleDeleteSubmit = async () => {
  //   const result = window.confirm("本当に削除してもよろしいですか？");

  //   if (result) {
  //     await DeleteUserService(content.id);
  //     await history.goBack();
  //   }
  // };
  return (
    <>
      <div className="custom-row">
        <PageTitle activeMenu="ユーザー情報" motherMenu="社員情報" />
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-body px-3">
            <div className="default-tab">
              <ul
                className="nav nav-tabs"
                style={{ borderBottom: "1px solid #727171" }}>
                <li className="nav-item">
                  <Link to="#profile-settings" data-toggle="tab">
                    社員情報
                  </Link>
                </li>
              </ul>
              <div className="tab-content">
                <div id="profile-settings">
                  <div className="pt-4 px-3">
                    <div className="settings-form">
                      <form onSubmit={handleSubmit}>
                        <div className="form-row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="font-weight-bold">Email</label>
                            <input
                              name="userEmail"
                              value={formValues.userEmail}
                              onChange={handleInputChange}
                              type="email"
                              placeholder="Email"
                              className="form-control"
                              // readOnly={admin !== "管理者A"}
                              style={
                                admin !== "管理者A"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="font-weight-bold">
                              パスワード
                            </label>
                            <input
                              name="userPassword"
                              value={formValues.userPassword}
                              onChange={handleInputChange}
                              type="password"
                              placeholder="パスワード"
                              className="form-control"
                              readOnly={admin !== "管理者A"}
                              style={
                                admin !== "管理者A"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="font-weight-bold">お名前</label>
                            <input
                              name="userName"
                              value={formValues.userName}
                              onChange={handleInputChange}
                              type="userUniqueId"
                              placeholder="お名前"
                              className="form-control"
                              // readOnly={admin !== "管理者A"}
                              style={
                                admin !== "管理者A"
                                  ? { backgroundColor: "#ddd" }
                                  : {}
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group mb-2 col-md-4">
                            <label className="font-weight-bold">権限</label>
                            <div className="form-group">
                              <select
                                name="userGrant"
                                value={formValues.userGrant}
                                onChange={handleInputChange}
                                className="form-control"
                                disabled={admin !== "管理者A"}
                                style={
                                  admin !== "管理者A"
                                    ? { backgroundColor: "#ddd" }
                                    : {}
                                }>
                                <option value="">権限選択</option>
                                <option value="SUDO">管理者</option>
                                <option value="DRAFT">スタッフ</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        {/* <div className="form-group mb-2">
                          <label className="font-weight-bold">写真</label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                              <div className="input-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">所属</label>
                          <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group mb-2">
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    所属A
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    所属B
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    所属C
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">制服サイズ</label>
                          <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group">
                                <select
                                  defaultValue={"option"}
                                  className="form-control">
                                  <option>xxs</option>
                                  <option>xs</option>
                                  <option>s</option>
                                  <option>m</option>
                                  <option>l</option>
                                  <option>xl</option>
                                  <option>xxl</option>
                                </select>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">通勤手段</label>
                          <div className="basic-form">
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group mb-2">
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    社用車
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    自家用車
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    バイク
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    原付
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    自転車
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    公共交通機関
                                  </label>
                                </div>
                                <div className="form-check form-check-inline disabled">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value=""
                                    />
                                    徒歩
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">運転免許証</label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    表面
                                  </label>
                                </div>
                              </div>
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    裏面
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            自賠責保険証券
                          </label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            任意保険証券
                          </label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">
                            自転車保険証券
                          </label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group mb-2  col-md-6">
                            <label className="font-weight-bold">
                              運転免許証
                            </label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                placeholder="運転免許証番号"
                                className="form-control"
                              />
                            </div>
                            <div className="input-group mb-3">
                              <form onSubmit={(e) => e.preventDefault()}>
                                <select
                                  defaultValue={"option"}
                                  className="form-control">
                                  <option>普通免許</option>
                                  <option>準中型免許</option>
                                  <option>中型免許</option>
                                  <option>大型免許</option>
                                  <option>原付免許</option>
                                  <option>小型特殊免許</option>
                                  <option>大型特殊免許</option>
                                  <option>普通二輪免許</option>
                                  <option>大型二輪免許</option>
                                  <option>けん引免許</option>
                                  <option>普通第二種免許</option>
                                  <option>中型第二種免許</option>
                                  <option>大型第二種免許</option>
                                  <option>大型特殊第二種免許</option>
                                  <option>けん引第二種免許</option>
                                  <option>仮運転免許</option>
                                </select>
                              </form>
                            </div>
                            <div className="input-group mb-3">
                              <input
                                type="date"
                                placeholder=""
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="font-weight-bold">最寄り駅</label>
                            <input
                              type="text"
                              placeholder="最寄り駅"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <label className="font-weight-bold">資格</label>
                          <div className="basic-form custom_file_input">
                            <form action="#">
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                              <div className="input-group mb-3">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                              <div className="input-group">
                                <div className="custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                  />
                                  <label className="custom-file-label">
                                    file選択
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div> */}

                        {/* <div className="form-row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="font-weight-bold">City</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group mb-2 col-md-4">
                            <label className="font-weight-bold">State</label>
                            <select
                              className="form-control"
                              id="inputState"
                              defaultValue="option-1">
                              <option value="option-1">Choose...</option>
                              <option value="option-2">Option 1</option>
                              <option value="option-3">Option 2</option>
                              <option value="option-4">Option 3</option>
                            </select>
                          </div>
                          <div className="form-group mb-2 col-md-2">
                            <label className="font-weight-bold">Zip</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="form-group mb-2">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="gridCheck"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="gridCheck">
                              Check me out
                            </label>
                          </div>
                        </div> */}
                        <Button
                          className="btn btn-primary mr-3 rounded btn-sm"
                          type="submit">
                          更新
                        </Button>
                        {/* <Button
                          className="btn mr-3 rounded btn-sm"
                          variant="danger"
                          onClick={handleDeleteSubmit}>
                          ユーザー削除
                        </Button> */}
                      </form>
                      {/* <Button
                          className="btn btn-primary mr-3 rounded btn-sm"
                          onClick={handleButtonClick}>
                          체크박스 테스트
                        </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserDetail;
