import { lazy, Suspense, useEffect } from "react";
/// Components
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Index from "./jsx/index";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import { useRecoilState } from "recoil";
import "./css/style.css";
import { checkTokenInterval } from "./services/ShinryuService";
import {
  getLocalStorage,
  removeLocalStorage,
  userIdState,
  userIsLoginState,
} from "./store/recoil";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 300);
  });
});
function App(props) {
  const dispatch = useDispatch();
  const [userIsLogin, setUserIsLogin] = useRecoilState(userIsLoginState);
  const [userId, setUserId] = useRecoilState(userIdState);

  function onLogout() {
    setUserIsLogin(false);
    removeLocalStorage("userToken");
    localStorage.removeItem("userId"); // remove userId from localStorage
    window.location.href = "/";
  }
  useEffect(() => {
    const timer = setInterval(() => {
      checkUserToken();
    }, 3000);
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const savedUserId = localStorage.getItem("userId"); // get userId from localStorage
    if (savedUserId) {
      setUserId(savedUserId); // initialize Recoil state with saved userId
    }
  }, []);

  const checkUserToken = async () => {
    const userToken = await getLocalStorage("userToken");
    // if (userToken) {
    //   const check = await checkTokenInterval(userToken);
    //   if (!check) {
    //     // alert("セッションタイムアウトでログアウトされます。")
    //     onLogout();
    //   }
    // }
  };

  useEffect(() => {
    checkAutoLogin(dispatch, props.history);
    checkUserIsLoigin();
  }, [dispatch, props.history]);

  const checkUserIsLoigin = async () => {
    const userToken = await getLocalStorage("userToken");
    const path = await getLocalStorage("path");
    if (userToken && path) {
      setUserIsLogin(true);
      props.history.push(path);
    }
  };

  let routes = (
    <Switch>
      <Route path="/" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
    </Switch>
  );
  if (userIsLogin) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }>
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }>
          {routes}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
