import { atom, selector } from "recoil";

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};
export const setSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

export const setAdminInfo = (key, value) => {
  localStorage.setItem(key, value);
};
export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};
export const getLocalStorage = async (key) => {
  const result = await localStorage.getItem(key);
  return result;
};

export const userIsLoginState = atom({
  key: "userIsLogin", // 임의 값 중복 안되게
  // default: await localStorage.getItem('userToken') ? true : false
  default: false,
});
export const userBelongToFilterListState = atom({
  key: "belongTofilterList",
  default: [],
});
export const userList = atom({
  key: "workers", // 임의 값 중복 안되게
  default: [], // <== list, string, number, boolean
});
export const userDetail = atom({
  key: "userdetil", // 임의 값 중복 안되게
  default: [], // <== list, string, number, boolean
});

export const userIdState = atom({
  key: "userIdState", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export const cateList = atom({
  key: "catlist", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});
export const formList = atom({
  key: "formlist", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});
export const formDetail = atom({
  key: "content", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});
export const notiList = atom({
  key: "notilist", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});
