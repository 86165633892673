import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  sendNotificationScheduler,
  sendNotificationStub,
  getJobsService,
  sendYoutube,
} from "../../../services/ShinryuService";
import PageTitle from "../../layouts/PageTitle";

const ArchiveGen = () => {
  const [title, setTitle] = useState("");
  const [subType01, setSubtype01] = useState("給与明細");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(0); // タイマーの状態
  const [jobs, setJobs] = useState([]);
  const handleSubmitUpload = async (e) => {
    e.preventDefault();

    // YouTube URL validation using a regex pattern
    const youtubeUrlPattern =
      /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;

    if (title === "") {
      alert("urlを入力してください");
      return;
    }

    // if (!youtubeUrlPattern.test(title)) {
    //   alert("YouTubeの正しいURLを入力してください");
    //   return;
    // }
    // // 5分（300秒）のカウントダウンを開始
    // setTimer(300);
    // setIsButtonDisabled(true);
    await sendYoutube(jobs[0], title);
  };
  const init = async () => {
    const job = await getJobsService();
    // console.log("좝", job);
    setJobs(job);
    return job; // 반환이 필요한 경우
  };

  useEffect(() => {
    const initialize = async () => {
      await init(); // 반환값이 필요 없으면 굳이 할당하지 않아도 됨
    };

    initialize(); // async 함수 실행
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);

      // カウントダウンが0になったらクリア
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setIsButtonDisabled(false); // タイマーが終わったらボタンを有効化
    }
  }, [timer]);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  return (
    <>
      <PageTitle activeMenu="アーカイブ作成" motherMenu="dashboard" />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div>
              <div className="settings-form">
                <form onSubmit={handleSubmitUpload}>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      placeholder="youtube url入力"
                      className="form-control"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <Button
                    className="btn btn-primary mr-3 rounded btn-sm"
                    disabled={isButtonDisabled}
                    type="submit">
                    {isButtonDisabled ? "作成中..." : "作成開始"}
                  </Button>
                  {/* タイマーの表示 */}
                  {timer > 0 && (
                    <div className="mt-2">
                      <p>残り時間: {formatTime(timer)}</p>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArchiveGen;
