import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import pic1 from "./../../../images/profile/small/pic1.jpg";
import Editable from "../Editable";

const SendGensen = () => {
  return (
    <>
      <PageTitle activeMenu="源泉徴収票" motherMenu="dashboard" />
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div>
              <div className="settings-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group mb-3">
                    <div className="basic-form custom_file_input">
                      <form action="#">
                        <div className="input-group mb-3">
                          <div className="custom-file">
                            <input type="file" className="custom-file-input" />
                            <label className="custom-file-label">
                              file選択
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      placeholder="件名"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group mb-3">
                    <div className="basic-form">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group">
                          <textarea
                            className="form-control2"
                            rows="7"
                            placeholder="メッセージ本文"
                            id="comment"></textarea>
                        </div>
                      </form>
                    </div>
                  </div>

                  <Button
                    className="btn btn-primary mr-3 rounded btn-sm"
                    type="submit">
                    一括送信
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SendGensen;
